import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import axios from 'axios'
import {
  store
} from './store/index'
import Notify from 'vue2-notify'
import VModal from 'vue-js-modal'
import KProgress from 'k-progress'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
const moment = require('moment')
require('moment/locale/pt-br')


Vue.component('k-progress', KProgress)
Vue.use(Notify, {
  position: 'bottom-right',
  visibility: 7000
})
Vue.use(Vuex)
Vue.config.productionTip = false
Vue.use(axios)
Vue.use(VModal)
Vue.use(require('vue-moment'), {
  moment
})

require("flatpickr/dist/themes/dark.css")

axios.defaults.baseURL = 'https://www.betlol.com.br/painel/'
// axios.defaults.baseURL = 'https://api-6kg.herokuapp.com/'
//axios.defaults.baseURL = 'http://127.0.0.1:8000/painel/'

new Vue({
  store,
  axios,
  router,
  render: h => h(App)
}).$mount('#app')