<template>
  <div class="s">
    <div class="carregando" v-if="!isLoadingPage">
      <div class="d-flex justify-content-center">
        <div class="spinner title-center">
          <atom-spinner
            :animation-duration="1000"
            :size="80"
            :color="'#1dbbff'"
          />
          <h3 class="text-muted" style="margin-top: 10px">
            Carregando dados...
          </h3>
        </div>
      </div>
    </div>
    <div class v-else>
      <modal
        name="my-first-modal"
        styles="background-color: #1e3a5c;"
        height="700px"
        width="800px"
      >
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">
                <a href="#" class="text-muted list-sort" data-sort="tables-row"
                  >Picks</a
                >
              </th>
              <template class v-for="(i, x) in champions[1]">
                <th scope="col" :key="i">
                  <a
                    href="#"
                    class="text-muted list-sort"
                    data-sort="tables-first"
                    v-if="x <= 7"
                    >Champ</a
                  >
                </th>
              </template>
            </tr>
          </thead>
          <tbody class="list">
            <tr v-for="(key, value) in champions" :key="value">
              <td
                scope="row"
                class="tables-row"
                v-if="champions[value].length > 0"
              >
                {{ value }}
              </td>
              <td scope="row" class="tables-row" v-for="(i, x) in key" :key="i">
                <span v-if="x <= 7">{{ i }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </modal>
      <div class="card">
        <div class="card-body text-center" v-if="havePermission">
          <div class="d-flex flex-wrap justify-content-center">
            <div class="p-2">
              <div class="text-muted" style="margin-bottom: 30px">
                Escolha os patches para analise:
              </div>
              <div class="row">
                <div
                  class="form-check"
                  v-for="i in listPatchResp"
                  :key="i"
                  style="margin-left: 10px"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checklistOne"
                    :value="i"
                    @click="checkPatch($event)"
                    :checked="isChecked(i)"
                  />
                  <label class="form-check-label">{{ i }}</label>
                </div>
              </div>
              <!-- <v-select
                multiple
                class="style-chooser"
                v-model="listPatch"
                :options="listPatchResp"
              ></v-select> -->
            </div>
          </div>

          <div class="text-muted" style="margin-bottom: 30px; margin-top: 30px">
            Escolha os dois times que irão se enfrentar!
            <br />
            Esta seção mapeia algumas possibilidades de chances acima de 70%.
            <br />
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- <a href="" class="card-avatar avatar avatar-lg mx-auto">
                    <img src="../assets/img/avatars/teams/team-logo-1.jpg" class="avatar-img rounded" >
              </a>-->
              <v-select
                class="style-chooser"
                v-model="clubea"
                :options="clubes"
              ></v-select>
            </div>
            <div class="col-md-6">
              <!-- <a href="" class="card-avatar avatar avatar-lg mx-auto">
                    <img src="../assets/img/avatars/teams/team-logo-1.jpg" class="avatar-img rounded" >
              </a>-->
              <v-select
                class="style-chooser"
                v-model="clubeb"
                :options="clubes"
              ></v-select>
            </div>
          </div>
          <!-- <div class="col-md-2 cust-grid-col">
          
          </div>-->
          <div v-if="isLoading" class="title-center" style="margin-top: 30px">
            <atom-spinner :size="50" :color="'#00d97e'" />
          </div>
          <button
            v-else
            type="button"
            id="btn-add-bet"
            class="btn btn-outline-success mb-2"
            style="margin-top: 30px"
            @click="submitAnalise"
          >
            Go!
          </button>
        </div>
        <!-- DIV RETORNO SE NÃO FOR VIP -->
        <div class="card-body text-center" v-else>
          <div class="mb-3">
            <h3>Ops, você não possui VIP ainda.</h3>
            <h3>
              Clique abaixo para saber como se tornar e tenha todas as analises
              prontas!!!
            </h3>
            <router-link :to="{ name: 'vip' }">
              <button
                type="button"
                id="btn-add-bet"
                class="btn btn-outline-success mb-2"
                style="margin-top: 30px; margin-right: 8px"
              >
                VIP!
              </button>
            </router-link>
          </div>
        </div>
        <Simplificada
          v-if="showSimpli"
          :resultado="resultado"
          :clubea="clubea"
          :clubeb="clubeb"
          :gold="gold"
          :tempoJogo="tempoJogo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import axios from 'axios'
import Simplificada from '@/components/AnaliseSimplificadaBoost.vue'
import {AtomSpinner} from 'epic-spinners'

export default {
    components: {
        vSelect,
        AtomSpinner,
        Simplificada,
    },
    data: function () {
        return {
            clubea: '',
            clubeb: '',
            resultado: [],
            headToHead: [],
            clubes: [],
            showResultado: false,
            tower: 11.5,
            handicapKills: 4.5,
            totalKills: 29.5,
            gold: 120,
            havePermission: false,
            kills_times: 12,
            handicapGold: 800,
            tempoJogo: '30',
            listPatch: [],
            listPatchResp: [],
            patch: '',
            champions: {},
            showHeadtohead: false,
            get_dragons: 4.5,
            showfb: false,
            showft: false,
            showfd: false,
            showfbaron: false,
            showtotalkills: false,
            showSimpli: false,
            showHerald: false,
            gameTime: false,
            drakeType: false,
            isLoaded: false,
            isLoading: false,
            isLoadingPage: false,
            haveConfronto: '',
            showPicks: false
        }
    },
    mounted () {
      const config = {
            headers: {
        // Set your Authorization to 'JWT', not Bearer!!!
        Authorization: `JWT ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
          },
        }            
      axios.get('check_status_perfil', config)
      .then(resp => {
          if (resp.data == 'true') {
            this.havePermission = true
            axios.get('list_clube_stats', config)
            .then(resp => {
                this.clubes = resp.data
            })
            .catch(err => {
                console.log(err)
            })
            axios.get('list_patch_analise_partida', config)
            .then(resp => {
              var patches_local = JSON.parse(localStorage.getItem('list_patch') || '[]')
              this.listPatch = patches_local
              this.listPatchResp = resp.data
              this.isLoadingPage = true
            })
            .catch(err => {
              console.log(err)
            })
            }
          else {
            this.havePermission = false
            this.isLoadingPage = true
          }
      })
    },
    methods: {        
      submitAnalise: function () {
          this.resultado = {}
          this.reloadComponents()
          this.showHeadtohead = false
          this.isLoading = true
          if (this.listPatch.length === 0) {
            this.$notify('Escolha um patch para analise!', 'error')
            this.isLoading = false
          }
          else {
            const config = {
              headers: {
                // Set your Authorization to 'JWT', not Bearer!!!
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
              },
            }   
              this.resultado = []
              axios.get(`stats-boost/${this.clubea}/${this.clubeb}/${this.listPatch}`, config)
                .then(resp => {
                    this.showResultado = true
                    this.resultado = resp.data
                     this.isLoaded = true
                     this.showSimpli = true
                     this.isLoading = false
                     console.log(resp.data)
                     if (resp.data.head_to_head == 'não existe confronto direto') {
                       this.haveConfronto = false
                     } else {
                       this.haveConfronto = true
                     }
                     localStorage.setItem('list_patch',JSON.stringify(this.listPatch))
              })
              .catch(err => {
              console.log(err)
              this.$notify('Erro ao consultar, verifique os campos digitados!', 'error')
              this.isLoading = false
              })
            }
        },
    isNumber: function (evt) {
      evt = (evt) || window.event
      var charCode = (evt.wich) ? evt.wich : evt.keyCode
      if (charCode === 44 || charCode === 45 || charCode === 47) {
        evt.preventDefault()
        this.$notify('Use apenas ponto nos valores!', 'error')
      } else {
        return true
      }
    },
    show: function () {
      this.$modal.show('my-first-modal');
    },
    hide: function () {
        this.$modal.hide('my-first-modal');
    },
    showPicaFunc: function () {
      this.showPicks = !this.showPicks
      this.showft = false
      this.showfbaron = false
      this.showfd = false
      this.showtotalkills = false
      this.gameTime = false
      this.showSimpli = false
      this.drakeType = false
      this.showHerald = false
    },
    showGeral: function () {
      this.showHeadtohead = false
    },
    showHeadToHead: function () {
      console.log(Object.keys(this.resultado.head_to_head).length)
      if (Object.keys(this.resultado.head_to_head).length === 0) {
        this.$notify('Não encontramos confrontos diretos para os times!', 'error')
      }
      this.showHeadtohead = true
    },
    showFirstBlood: function () {
      this.showfb = !this.showfb
      this.showft = false
      this.showfbaron = false
      this.showfd = false
      this.showtotalkills = false
      this.gameTime = false
      this.showSimpli = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showFirstTower: function () {
      this.showft = !this.showft
      this.showfb = false
      this.showfbaron = false
      this.showfd = false
      this.showtotalkills = false
      this.gameTime = false
      this.showSimpli = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showFirstBaron: function () {
      this.showfbaron = !this.showfbaron
      this.showfb = false
      this.showft = false
      this.showfd = false
      this.showtotalkills = false
      this.gameTime = false
      this.showSimpli = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showFirstDrake: function () {
      this.showfd = !this.showfd
      this.showfb = false
      this.showft = false
      this.showfbaron = false
      this.showtotalkills = false
      this.gameTime = false
      this.showSimpli = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showTotalKills: function () {
      this.showtotalkills = !this.showtotalkills
      this.showfd = false
      this.showfb = false
      this.showft = false
      this.showfbaron = false
      this.gameTime = false
      this.showSimpli = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showSimplificada: function () {
      this.showSimpli = !this.showSimpli
      this.showfd = false
      this.showfb = false
      this.showft = false
      this.showfbaron = false
      this.showtotalkills = false
      this.gameTime = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showGameTime: function () {
      this.gameTime = !this.gameTime
      this.showSimpli = false
      this.showfd = false
      this.showfb = false
      this.showft = false
      this.showfbaron = false
      this.showtotalkills = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showFirstHerald: function () {
      this.showHerald = !this.showHerald
      this.showSimpli = false
      this.showfd = false
      this.showfb = false
      this.showft = false
      this.showfbaron = false
      this.showtotalkills = false
      this.showgameTime = false
      this.drakeType = false
      this.showPicks = false
    },
    showDrakeType: function () {
      this.drakeType = !this.drakeType
      this.showHerald = false
      this.showSimpli = false
      this.showfd = false
      this.showfb = false
      this.showft = false
      this.showfbaron = false
      this.showtotalkills = false
      this.showgameTime = false
      this.showPicks = false
    },
    reloadComponents: function() {
      this.showfb = false
      this.showft = false
      this.showfd = false
      this.showfbaron = false
      this.showtotalkills = false
      this.showSimpli = false
      this.gameTime = false
      this.showHerald = false
      this.drakeType = false
      this.showPicks = false
    },
      checkPatch: function (e) {
      var is_checked = e.target.checked
      var value = e.target.defaultValue
      if (is_checked == true) {
        this.listPatch.push(value)
      } else {
        var index = this.listPatch.indexOf(value);
        if (index !== -1) {
          this.listPatch.splice(index, 1);
        }
      }
    },
    isChecked: function (value) {
      return this.listPatch.includes(value)
    }
    },
}
</script>

<style>
.rola {
  display: flex;
  flex-wrap: wrap;
}
.cust-grid {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 15px;
}
.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-item-1 {
  flex: 1;
}

.cust-grid-col {
  float: none;
}
.vs__selected {
  background-color: #49688e;
}
.title-center {
  display: grid;
  place-items: center;
}
.carregando {
  padding-top: 5cm;
}
</style>